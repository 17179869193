import { useContext, useEffect } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { AppContext } from '../context/contexts';

/* 
  Customised Higher-Order component from Algolia
  https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/

*/
const AlgoliaTagRefinement = ({ refine }) => {
  const appContext = useContext(AppContext);

  const { filteredTags } = appContext;
  useEffect(() => {
    refine(filteredTags);
  }, [filteredTags]);

  return null;
};

export default connectRefinementList(AlgoliaTagRefinement);
