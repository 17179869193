import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './BackButton.module.scss';

const BackButton = ({ returnPath, className }) => {
  const history = useHistory();
  const combinedClassNames = classNames(styles.BackButton, className);

  return (
    <>
      {returnPath ? (
        <div className={combinedClassNames}>
          <Link to={returnPath}>
            <button type="button">Go Back</button>
          </Link>
        </div>
      ) : (
        <div className={combinedClassNames}>
          <button type="button" onClick={() => history.goBack()}>
            Go Back
          </button>
        </div>
      )}
    </>
  );
};
export default BackButton;
