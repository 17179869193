import algoliasearch from 'algoliasearch';

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME;

export { algoliaClient, indexName };
