/* 
    For some reasons using a constructor rather than a literal creates a bug
    where calling validateTextEditorBody(bodyValue) returns an alternating true-false-true value.
    My hunch is that the browser tries to implement some caching optimisations when using
    the constructor for better performance, and this results in memory leaks conflicting with the
    asynchronous change of state from react? A wild guess, I actually have no idea.
*/
const reg = new RegExp('(?:(?<=<.*?>))[^<\\\n]+', 'g');

export const validateTextEditorBody = (value) => /(?:(?<=<.*?>))[^<\\\n]+/g.test(value);
export const extractFromHtml = (value) => value.match(reg);
export const useQuery = (useLocation) => new URLSearchParams(useLocation().search);
