import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../../context/contexts';
import Header from '../../Header/Header';
import styles from './AuthenticateURL.module.scss';

const AuthenticateURL = ({ ...props }) => {
  const authContext = useContext(AuthContext);
  const { loading, registerUser, isAuthenticated } = authContext;
  const authenticate = async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    registerUser(code);
  };
  useEffect(() => {
    authenticate();
    // eslint-disable-next-line
  }, [props.history]);
  if (loading)
    return (
      <div className={styles.AuthenticateURL}>
        <Header authenticated={false} />
        <h1>
          Authenticating<span className={styles.one}>.</span>
          <span className={styles.two}>.</span>
          <span className={styles.three}>.</span>
        </h1>
      </div>
    );
  return isAuthenticated ? <Redirect to="/" /> : <Redirect to="/login" />;
};

export default AuthenticateURL;
