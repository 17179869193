import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './UserInfo.module.scss';
import { AuthContext } from '../../context/contexts';

const cx = classNames.bind(styles);
const UserInfo = ({
  timestamp,
  className,
  insideAnswer = false,
  userId,
  transparentBackground,
}) => {
  const authContext = useContext(AuthContext);

  const { allUsers } = authContext;

  const currentUser = allUsers.filter((user) => user.user_id === userId)[0];
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateAndTime = (date) =>
    `${shortMonths[date.getMonth()]} ${date.getDate()} '${date
      .getFullYear()
      .toString()
      .substring(2)} at ${`${date.getHours()}`.padStart(2, '0')}:${`${date.getMinutes()}`.padStart(
      2,
      '0'
    )}`;

  return (
    <div
      className={cx(styles.UserInfo, className, {
        transparent: transparentBackground,
      })}
    >
      <p className={styles['time-asked']}>
        {insideAnswer ? 'answered' : 'asked'} {dateAndTime(new Date(timestamp))}
      </p>
      <div className={styles.identity}>
        <Link to={`/profile/${userId}`}>
          {currentUser && currentUser.avatar && (
            <img
              src={`https://cdn.discordapp.com/avatars/${currentUser.user_id}/${currentUser.avatar}.png`}
              alt="User"
            />
          )}
        </Link>
        <Link to={`/profile/${userId}`}>
          <span>{currentUser && currentUser.username}</span>
        </Link>
      </div>
    </div>
  );
};

export default UserInfo;
