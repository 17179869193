import React, { useState, useEffect, useContext } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import hljs from 'highlight.js';
import { decode } from 'html-entities';

import AnswerBox from '../../Answers/AnswerBox/AnswerBox';
import AnswersList from '../../Answers/AnswersList/AnswersList';
import BackButton from '../../buttons/BackButton/BackButton';
import UserInfo from '../../UserInfo/UserInfo';
import { AppContext } from '../../../context/contexts';
import EditMode from '../../EditDelete/EditMode/EditMode';
import DeleteMode from '../../EditDelete/DeleteMode/DeleteMode';
import NormalMode from '../../EditDelete/NormalMode/NormalMode';
import './QuestionPage.scss';

hljs.configure({
  languages: ['html', 'bash', 'css', 'markdown', 'javascript', 'json', 'scss', 'sql'],
});

const QuestionPage = ({ questions, user }) => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [titleInput, setTitleInput] = useState('');
  const [bodyInput, setBodyInput] = useState('');
  const [editError, setEditError] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState('');

  const appContext = useContext(AppContext);

  const { updateQuestion, deleteQuestion } = appContext;
  const currentQuestion =
    questions.length > 0 && questions.filter((question) => question._id === id)[0];

  let deletedQuestion = false;
  // May need to change up the admin check once the Profile PR has come through.
  if (currentQuestion === undefined) {
    deletedQuestion = true;
  }

  useEffect(() => {
    if (!deletedQuestion) {
      setTitleInput(currentQuestion.title);
      setBodyInput(currentQuestion.body);
    }
  }, [currentQuestion]);

  const handleTitleInput = (e) => {
    setTitleInput(e.target.value);
  };

  const removeEditError = () => {
    setEditError(false);
    setEditErrorMessage('');
  };

  const cancelEdit = () => {
    setEditMode(false);
    setDeleteMode(false);
    setTitleInput(currentQuestion.title);
    setBodyInput(currentQuestion.body);
  };

  const confirmEdit = () => {
    // Check if updated values are different from original.
    const titleDiff = currentQuestion.title.localeCompare(titleInput);
    const bodyDiff = currentQuestion.body.localeCompare(bodyInput);
    if (titleDiff !== 0 || bodyDiff !== 0) {
      setRequestLoading(true);
      updateQuestion(id, titleInput, bodyInput)
        .then(() => {
          setRequestLoading(false);
          setEditMode(false);
        })
        .catch((err) => {
          console.warn(err);
          setRequestLoading(false);
          setEditErrorMessage(
            'There appears to be issue with your edit. Check there is enough characters in the Body and Title.'
          );
          setEditError(true);
        });
    } else {
      setEditErrorMessage('No difference!');
      setEditError(true);
    }
  };

  const confirmDelete = () => {
    setRequestLoading(true);
    deleteQuestion(id).then(() => {
      setRequestLoading(false);
      setRedirect(true);
    });
  };

  const regex = /(?<=<pre>)[\s\S]*?(?=<\/pre>)/g;

  const highlightCode = (code) =>
    code.replace(
      regex,
      (codeBlock) => `<code class="hljs">${hljs.highlightAuto(codeBlock).value}</code>`
    );

  let editingRights = false;
  if (!deletedQuestion && (user.admin || currentQuestion.user_id === user.user_id)) {
    editingRights = true;
  }

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <div className="QuestionPage">
      {deletedQuestion ? (
        <div className="deleted-question-container">
          Question appears to be deleted... <BackButton returnPath="/" />
        </div>
      ) : (
        <>
          <BackButton returnPath="/" />
          {!editMode && (
            <>
              <h1>{currentQuestion.title}</h1>

              <div
                className="question-container"
                dangerouslySetInnerHTML={{ __html: highlightCode(decode(currentQuestion.body)) }}
              />
            </>
          )}
          {editMode && (
            <EditMode
              mode="question"
              bodyInput={bodyInput}
              setBodyInput={setBodyInput}
              editError={editError}
              removeEditError={removeEditError}
              titleInput={titleInput}
              handleTitleInput={handleTitleInput}
              editingRights={editingRights}
              editErrorMessage={editErrorMessage}
              requestLoading={requestLoading}
              editMode={editMode}
              confirmEdit={confirmEdit}
              cancelEdit={cancelEdit}
            />
          )}
          {editingRights && !editMode && !deleteMode && (
            <NormalMode setEditMode={setEditMode} setDeleteMode={setDeleteMode} />
          )}
          {editingRights && deleteMode && (
            <DeleteMode
              requestLoading={requestLoading}
              confirmDelete={confirmDelete}
              cancelEdit={cancelEdit}
            />
          )}
          <div className="question-info-container">
            <UserInfo
              timestamp={currentQuestion.createdAt}
              className="QuestionPage-ask-user"
              userId={currentQuestion.user_id}
            />
          </div>
          <AnswerBox questionId={id} user={user} />
          {currentQuestion.answers.length > 0 && (
            <AnswersList currentQuestion={currentQuestion} editingRights={editingRights} />
          )}
        </>
      )}
    </div>
  );
};
export default QuestionPage;
