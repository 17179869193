import React from 'react';
import notFound from '../../../assets/img/404.gif';

const NotFound = () => (
  <div>
    <h1>Not found</h1>
    <img src={notFound} alt="Not found" />
  </div>
);

export default NotFound;
