export const SET_LOADING = 'SET_LOADING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const NO_USER = 'NO_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const REGISTER_USER_FROM_COOKIE = 'REGISTER_USER_FROM_COOKIE';

// Questions
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const QUESTIONS_LOADING = 'QUESTIONS_LOADING';
export const QUESTIONS_FETCH_FAILURE = 'QUESTIONS_FETCH_FAILURE';
export const SUBMIT_BUTTON_INIT = 'SUBMIT_BUTTON_INIT';
export const SUBMIT_BUTTON_SUCCESS = 'SUBMIT_BUTTON_SUCCESS';
export const SUBMIT_BUTTON_FAILURE = 'SUBMIT_BUTTON_FAILURE';
export const RESET_BUTTON_FAILURE = 'RESET_BUTTON_FAILURE';
export const RESET_POST = 'RESET_POST';
export const RECENTLY_ASKED_QUESTION = 'RECENTLY_ASKED_QUESTION';
export const REMOVE_RECENTLY_ASKED_QUESTION = 'REMOVE_RECENTLY_ASKED_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';

// Tags
export const ADD_FILTER_TAG = 'ADD_FILTER_TAG';
export const REMOVE_FILTER_TAG = 'REMOVE_FILTER_TAG';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const ADD_SEARCH_STRING = 'ADD_SEARCH_STRING';
export const REMOVE_SEARCH_STRING = 'REMOVE_SEARCH_STRING';
export const REMOVE_ALL_FILTER_TAGS = 'REMOVE_ALL_FILTER_TAGS';
// Algolia
export const FILTER_QUESTIONS_BY_HITS = 'FILTER_QUESTIONS_BY_HITS';

// users
export const ALL_USERS = 'ALL_USERS';

export const UPDATE_USER_IN_ALL_USERS = 'UPDATE_USER_IN_ALL_USERS';

// Bugs
export const REPORT_BUG_INIT = 'REPORT_BUG_INIT';
export const REPORT_BUG_SUCCESS = 'REPORT_BUG_SUCCESS';
export const REPORT_BUG_FAILURE = 'REPORT_BUG_FAILURE';
export const RESET_BUG_FAILURE = 'RESET_BUG_FAILURE';
export const RESET_BUG = 'RESET_BUG';
