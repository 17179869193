import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import styles from './ProfileAvatar.module.scss';

import { AuthContext } from '../../../context/contexts';

const cx = classNames.bind(styles);

const ProfileAvatar = ({ className, width, height, hoverEffect, user }) => {
  const authContext = useContext(AuthContext);

  const currentUser = user || authContext.user;

  return (
    <div
      className={cx(styles.ProfileAvatar, className, {
        hoverEffect,
      })}
      style={{ width, height }}
    >
      {currentUser && (
        <img
          src={`https://cdn.discordapp.com/avatars/${currentUser.user_id}/${currentUser.avatar}.png`}
          alt={currentUser.username}
        />
      )}
      {hoverEffect && <p>View profile</p>}
    </div>
  );
};

export default ProfileAvatar;
