import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/contexts';

import discordLogo from '../../../assets/img/discord.png';
import styles from './Login.module.scss';
import Background from '../../Background/Background';
import Header from '../../Header/Header';

const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND : '';

const Login = () => {
  const authContext = useContext(AuthContext);
  const { error } = authContext;
  const [ready, setReady] = useState(false);
  const [discordAuthScreen, setDiscordAuthScreen] = useState('');

  useEffect(() => {
    axios
      .get(`${api}/auth/discord`)
      .then((res) => {
        setDiscordAuthScreen(res.data.body);
      })
      .catch((err) => {
        console.log('login err', err);
      });
  }, []);

  return (
    <>
      <div className={styles['Header-wrapper']}>
        <Header authenticated={false} />
      </div>

      <div className={styles.mainWrapper}>
        <Background />
        <div className="container">
          <div className="row justify-content-center">
            <div className={styles.wrapper}>
              Ready to{' '}
              <div className={styles.glitch} data-text="code">
                code
              </div>
              <span className={styles.ready}>{!ready ? <span>?</span> : <span>!</span>}</span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className={`${styles.card} `}>
              <div className={`${styles.cardHeader}pb-5`}>
                <div className="text-center">
                  {error === 'no-role' ? (
                    <p className={styles['sign-in-text__error']}>
                      You require a role on our Discord server to access this application. <br />
                      Get in touch with an admin if you think this is an error.
                    </p>
                  ) : (
                    <p className={styles['sign-in-text']}>Sign in with</p>
                  )}
                </div>
                <div className="btn-wrapper text-center">
                  {discordAuthScreen ? (
                    <a
                      href={discordAuthScreen}
                      onMouseEnter={() => setReady(!ready)}
                      onMouseLeave={() => setReady(!ready)}
                    >
                      <img
                        src={discordLogo}
                        className={styles['discord-logo']}
                        alt="Discord Sign In"
                        width="120px"
                      />
                    </a>
                  ) : (
                    <div className={styles['animated-ellipsis']}>
                      <span className={styles.one}>.</span>
                      <span className={styles.two}>.</span>
                      <span className={styles.three}>.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
