/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import classNames from 'classnames';
import { FaSearch } from 'react-icons/fa';
import { AppContext } from '../../context/contexts';

import styles from './SearchBox.module.scss';

/* 
  Customised Higher-Order component from Algolia
  https://www.algolia.com/doc/api-reference/widgets/search-box/react/
*/
const SearchBox = ({ refine, className }) => {
  const [searchInput, setSearchInput] = useState('');
  const appContext = useContext(AppContext);
  const { addSearchString, searchString } = appContext;
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    if (!searchString) {
      refine('');
    }
  }, [searchString]);

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.value.length > 2) {
        refine(searchInput);
        addSearchString(searchInput);
        setSearchInput('');
      }
    }
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <div className={classNames(styles.aisSearchBox, className)}>
      <form
        onClick={() => focusInput()}
        onKeyDown={() => focusInput()}
        noValidate
        role="search"
        className={styles.aisSearchBoxForm}
      >
        <FaSearch />
        <input
          type="search"
          ref={inputRef}
          value={searchInput}
          onChange={handleChange}
          placeholder={searchString || 'Search...'}
          onKeyDown={handleEnter}
        />
      </form>
    </div>
  );
};
export default connectSearchBox(SearchBox);
