import { useContext, useEffect, useState } from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { AppContext } from '../context/contexts';

/* 
  Customised Higher-Order component from Algolia
  https://www.algolia.com/doc/api-reference/widgets/hits/react/
*/
const AlgoliaConnectHits = ({ hits }) => {
  const [currentHit, setCurrentHit] = useState([]);
  const [currentAllQuestionsLength, setCurrentAllQuestionsLength] = useState(0);
  const appContext = useContext(AppContext);
  const { filterQuestionsByHits, allQuestions } = appContext;

  useEffect(() => {
    // Hits are returned on loading AND loaded. compare to previous result to prevent rerender.
    const same = JSON.stringify(hits) === JSON.stringify(currentHit);
    const sameQuestionsLength = currentAllQuestionsLength === allQuestions.length;

    if (allQuestions.length > 0 && !same) {
      setCurrentHit(hits);
      filterQuestionsByHits(hits);
    }

    if (!sameQuestionsLength) {
      setCurrentAllQuestionsLength(allQuestions.length);
      filterQuestionsByHits(hits);
    }
  }, [hits, allQuestions]);
  return null;
};
export default connectHits(AlgoliaConnectHits);
