import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from 'react-instantsearch-dom';
// import { IoFunnel } from 'react-icons/io5';
import { AppContext, AuthContext } from '../../../context/contexts';
import QuestionListItem from '../QuestionListItem/QuestionListItem';
import SortBy from '../../../algolia/SortBy';
import { indexName } from '../../../algolia/algoliaClient';
import Tag from '../../Tag/Tag';
import Ellipsis from '../../Ellipsis/Ellipsis';
import styles from './QuestionList.module.scss';
import './pagination.scss';

const QuestionList = ({ filteredQuestions, children, userPresent }) => {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const {
    recentlyAskedQuestion,
    removeRecentlyAskedQuestion,
    filteredTags,
    searchString,
    removeSearchString,
    questionsLoading,
  } = appContext;

  const { allUsers } = authContext;

  return (
    <div className={styles.QuestionList}>
      <div className={styles['header-container']}>
        <div className={styles.heading}>
          <h2>Latest Questions</h2>
          <div className={styles['SortBy-container']}>
            <SortBy
              defaultRefinement={`${indexName}_SORT_NEW`}
              limit={10}
              items={[
                { value: `${indexName}_SORT_NEW`, label: 'Newest' },
                { value: `${indexName}_SORT_OLD`, label: 'Oldest' },
                { value: `${indexName}_SORT_ANSWERED`, label: 'Answered' },
                { value: `${indexName}_SORT_UNANSWERED`, label: 'Unanswered' },
              ]}
            />
          </div>
        </div>
        {children}
        {filteredTags.length > 0 && (
          <div className={styles.filtered}>
            Filtered By:{' '}
            {filteredTags.length > 0 &&
              filteredTags.map((tag) => (
                <Tag currentlyFilteringByTag key={tag}>
                  {tag}
                </Tag>
              ))}
          </div>
        )}
      </div>

      <div className={styles['questionList-Items-container']}>
        {searchString && (
          <h3 className={styles['search-query']}>
            Searching for{' '}
            <span className={styles['search-query-string']}>{`'${searchString}'`}</span>{' '}
            <span
              role="button"
              tabIndex={0}
              onClick={() => removeSearchString()}
              onKeyDown={() => removeSearchString()}
              className={styles['search-query-delete']}
            >
              ×
            </span>
          </h3>
        )}
        {questionsLoading ||
          (allUsers.length === 0 && (
            <div className={styles['missing-questions']}>
              <span className={styles.text}>Questions Loading</span>
              <Ellipsis />
            </div>
          ))}
        {filteredQuestions.length > 0 &&
          userPresent &&
          filteredQuestions.map((question) => (
            <QuestionListItem
              questionDetails={question}
              key={question._id}
              recentlyAskedQuestion={recentlyAskedQuestion}
              removeRecentlyAskedQuestion={removeRecentlyAskedQuestion}
            />
          ))}
        {!questionsLoading && filteredQuestions.length === 0 && allUsers.length > 0 && (
          <div className={styles['missing-questions']}>
            No questions found. Try removing some filters or&nbsp;
            <Link to="/askaquestion">Ask a question</Link>
          </div>
        )}
      </div>
      <Pagination className="QuestionList-pagination" />
      {searchString && (
        <div className={styles['algolia-search-container']}>
          <img src="/images/search-by-algolia-light-background.png" alt="Algolia search" />
        </div>
      )}
    </div>
  );
};

export default QuestionList;
