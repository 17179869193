import React from 'react';
import classNames from 'classnames';
import styles from './Background.module.scss';

const Background = () => (
  <>
    <div className={styles.Background}>
      <div className={classNames(styles.line, styles['line-1'])}>
        <div
          className={classNames(styles.wave, styles.wave1)}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave1.png)` }}
        />
      </div>
      <div className={classNames(styles.line, styles['line-2'])}>
        <div
          className={classNames(styles.wave, styles.wave2)}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave2.png)` }}
        />
      </div>
      <div className={classNames(styles.line, styles['line-3'])}>
        <div
          className={classNames(styles.wave, styles.wave3)}
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave3.png)` }}
        />
      </div>
    </div>
  </>
);

export default Background;
