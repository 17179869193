import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../context/contexts';
import { useQuery } from '../../../utils/utils';
import Answer from './Answer';
import styles from './AnswersList.module.scss';

const AnswersList = ({ currentQuestion: question, editingRights }) => {
  const appContext = useContext(AppContext);
  const numberOfAnswersTitleString =
    question.answers.length > 1
      ? `${question.answers.length} Answers`
      : `${question.answers.length} Answer`;
  const { approveAnswer } = appContext;

  const refs = useRef([]);
  const [scrolledTo, setScrolledTo] = useState('');

  const query = useQuery(useLocation);
  const answerId = query.get('answerId');

  // Move marked answer to the top.
  if (question.answers.length > 0) {
    question.answers.forEach((answer, i) => {
      if (answer.markedAsAnswer === true) {
        question.answers.splice(i, 1);
        question.answers.unshift(answer);
      }
    });
  }

  useEffect(() => {
    refs.current.forEach((ref) => {
      if (ref.answerId === answerId) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setTimeout(() => setScrolledTo(answerId), 500);
      }
    });
  }, []);

  return (
    <>
      <hr className={styles.break} />
      <div className={styles.AnswersListContainer}>
        <h3>{numberOfAnswersTitleString}</h3>
        {question.answers.map((answer, i) => (
          // eslint-disable-next-line
          <div key={answer._id} ref={(el) => (refs.current[i] = el) && (el.answerId = answer._id)}>
            <Answer
              editingRights={editingRights}
              answer={answer}
              answerId={answerId}
              question={question}
              approveAnswer={approveAnswer}
              key={answer._id}
              scrolledTo={scrolledTo}
              setScrolledTo={setScrolledTo}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default AnswersList;
