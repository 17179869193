import axios from 'axios';
import React, { useReducer, useContext } from 'react';

import { AppContext, AuthContext } from '../contexts';
import QuestionReducer from './AppReducer';

import {
  QUESTIONS_LOADING,
  SET_QUESTIONS,
  QUESTIONS_FETCH_FAILURE,
  SUBMIT_BUTTON_INIT,
  SUBMIT_BUTTON_SUCCESS,
  SUBMIT_BUTTON_FAILURE,
  RESET_POST,
  RECENTLY_ASKED_QUESTION,
  REMOVE_RECENTLY_ASKED_QUESTION,
  ADD_FILTER_TAG,
  REMOVE_FILTER_TAG,
  GET_ALL_TAGS,
  UPDATE_QUESTION,
  FILTER_QUESTIONS_BY_HITS,
  ADD_SEARCH_STRING,
  REMOVE_SEARCH_STRING,
  RESET_BUTTON_FAILURE,
  REMOVE_ALL_FILTER_TAGS,
} from '../types';

// const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND : '';

const AppState = (props) => {
  const { children } = props;
  const initialState = {
    allQuestions: [],
    filteredQuestions: [],
    questionsLoading: true,
    questionsFetchFailure: null,
    api: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND : '',
    submitButtonLoading: false,
    submitButtonError: false,
    submitButtonSuccess: false,
    timeLastDataUpdate: '',
    recentlyAskedQuestion: {},
    filteredTags: [],
    allTags: [],
    searchString: '',
  };

  const [state, dispatch] = useReducer(QuestionReducer, initialState);
  const authContext = useContext(AuthContext);
  const { logout, getAllUsers } = authContext;

  const setLoading = () => {
    dispatch({ type: QUESTIONS_LOADING });
  };

  const checkIfObjectIsEmpty = (obj) =>
    obj && Object.keys(obj).length === 0 && obj.constructor === Object;

  const filterQuestionsByHits = (hits) => {
    dispatch({ type: FILTER_QUESTIONS_BY_HITS, payload: hits });
  };

  const fetchTags = () => {
    const token = localStorage.getItem('token');

    axios
      .get(`${state.api}/questions/tags`, {
        headers: { authorization: token },
      })
      .then((res) => {
        dispatch({ type: GET_ALL_TAGS, payload: res.data.tags });
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  const addFilterTag = (tag) => {
    if (!state.filteredTags.includes(tag)) {
      dispatch({ type: ADD_FILTER_TAG, payload: tag });
    }
  };

  const removeFilterTag = (tag) => {
    dispatch({ type: REMOVE_FILTER_TAG, payload: tag });
  };

  const addSearchString = (string) => {
    dispatch({ type: ADD_SEARCH_STRING, payload: string });
  };

  const removeSearchString = () => {
    dispatch({ type: REMOVE_SEARCH_STRING });
  };

  const removeAllFilterTags = () => {
    dispatch({ type: REMOVE_ALL_FILTER_TAGS });
  };

  const fetchQuestions = async () => {
    setLoading();
    const token = localStorage.getItem('token');
    axios
      .get(`${state.api}/questions`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({ type: SET_QUESTIONS, payload: res.data.questions.reverse() });
        } else {
          dispatch({ type: QUESTIONS_FETCH_FAILURE, payload: res.data });
        }
      })
      .catch((err) => {
        console.log('err', err);
        dispatch({ type: QUESTIONS_FETCH_FAILURE, payload: err });
        logout();
      });
  };

  const resetPost = () => {
    dispatch({ type: RESET_POST });
  };

  const removeRecentlyAskedQuestion = () => {
    dispatch({ type: REMOVE_RECENTLY_ASKED_QUESTION });
  };

  const updateQuestion = (id, title, body) => {
    const token = localStorage.getItem('token');

    return axios
      .post(
        `${state.api}/questions/update`,
        {
          id,
          title,
          body,
        },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        fetchQuestions();
        return res.data.success;
      });
  };

  const deleteQuestion = (id) => {
    const token = localStorage.getItem('token');
    return axios({
      url: `${state.api}/questions/${id}`,
      method: 'delete',
      data: { success: true },
      headers: { Authorization: token },
    })
      .then((res) => {
        getAllUsers();
        fetchQuestions();
        return res.data;
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const postQuestion = async (questionData) => {
    dispatch({ type: SUBMIT_BUTTON_INIT });
    const token = localStorage.getItem('token');
    axios
      .post(`${state.api}/questions`, questionData, {
        headers: { authorization: token },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({ type: RECENTLY_ASKED_QUESTION, payload: res.data.question });
          dispatch({ type: SUBMIT_BUTTON_SUCCESS, payload: res.data });
          fetchQuestions();
          resetPost();
        } else {
          dispatch({ type: SUBMIT_BUTTON_FAILURE });
        }
      })
      .catch((err) => {
        dispatch({ type: SUBMIT_BUTTON_FAILURE, payload: err });
      });
  };

  const addNewAnswer = async (answerData) => {
    // eslint-disable-next-line no-param-reassign
    const token = localStorage.getItem('token');

    axios
      .post(`${state.api}/questions/post-answer`, answerData, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.success) dispatch({ type: UPDATE_QUESTION, payload: res.data.question });
      })
      .catch((e) => console.log(e));
  };

  const approveAnswer = (answer) => {
    const token = localStorage.getItem('token');

    axios
      .put(
        `${state.api}/questions/approve-answer/${answer.question_id}/${answer._id}`,
        { success: true },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        if (res.data.success) dispatch({ type: UPDATE_QUESTION, payload: res.data.question });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const updateAnswer = ({ answerId, bodyInput, questionId }) => {
    const token = localStorage.getItem('token');

    return axios
      .put(
        `${state.api}/questions/update-answer/${questionId}/${answerId}`,
        { bodyInput },
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        if (res.data.success) dispatch({ type: UPDATE_QUESTION, payload: res.data.question });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const deleteAnswer = ({ answerId, questionId }) => {
    const token = localStorage.getItem('token');

    return axios
      .delete(`${state.api}/questions/delete-answer/${questionId}/${answerId}`, {
        headers: { Authorization: token },
      })
      .then(() => fetchQuestions());
  };

  const resetButtonFailure = () => dispatch({ type: RESET_BUTTON_FAILURE });

  const reportBug = (bugData) => {
    dispatch({ type: SUBMIT_BUTTON_INIT });

    const token = localStorage.getItem('token');

    axios
      .post(`${state.api}/users/report-bug`, bugData, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch({ type: SUBMIT_BUTTON_SUCCESS });
          resetPost();
        } else {
          dispatch({ type: SUBMIT_BUTTON_FAILURE });
        }
      })
      .catch((err) => dispatch({ type: SUBMIT_BUTTON_FAILURE, payload: err }));
  };

  return (
    <AppContext.Provider
      value={{
        allQuestions: state.allQuestions,
        questionsLoading: state.questionsLoading,
        api: state.api,
        lastDataUpdate: state.lastDataUpdate,
        submitButtonLoading: state.submitButtonLoading,
        submitButtonError: state.submitButtonError,
        submitButtonSuccess: state.submitButtonSuccess,
        recentlyAskedQuestion: state.recentlyAskedQuestion,
        filteredTags: state.filteredTags,
        filteredQuestions: state.filteredQuestions,
        allTags: state.allTags,
        searchString: state.searchString,
        postQuestion,
        fetchQuestions,
        resetPost,
        removeRecentlyAskedQuestion,
        addFilterTag,
        removeFilterTag,
        fetchTags,
        addNewAnswer,
        filterQuestionsByHits,
        checkIfObjectIsEmpty,
        addSearchString,
        removeSearchString,
        updateQuestion,
        deleteQuestion,
        approveAnswer,
        updateAnswer,
        deleteAnswer,
        reportBug,
        resetButtonFailure,
        removeAllFilterTags,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppState;
