import {
  SET_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ALL_USERS,
  LOGOUT,
  REGISTER_USER_FROM_COOKIE,
  UPDATE_USER_IN_ALL_USERS,
} from '../types';

// eslint-disable-next-line
export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.profile,
        loading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        error: action.payload,
        loading: false,
      };
    case UPDATE_USER_IN_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case REGISTER_USER_FROM_COOKIE:
      return {
        ...state,
        user: action.payload,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};
