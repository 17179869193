import React from 'react';
import TextEditor from '../../../TextEditor/TextEditor';

const AnswerMode = ({ bodyInput, setBodyInput, editError, removeEditError }) => (
  <div>
    <div>
      <TextEditor
        value={bodyInput}
        setValue={setBodyInput}
        onFocus={() => {
          if (editError) removeEditError();
        }}
      />
    </div>
  </div>
);

export default AnswerMode;
