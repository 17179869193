import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthState from './context/auth/AuthState';
import AppState from './context/app/AppState';
import ProtectedRoute from './components/auth/ProtectedRoute/ProtectedRoute';
import Login from './components/auth/Login/Login';
import NotFound from './components/misc/NotFound/NotFound';
import Main from './components/layout/Main/Main';
import AuthenticateURL from './components/auth/AuthenticateURL/AuthenticateURL';

const App = () => (
  <AuthState>
    <div>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/callback" render={(props) => <AuthenticateURL {...props} />} />
        <AppState>
          <ProtectedRoute path="/" component={Main} />
        </AppState>
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  </AuthState>
);

export default App;
