import React from 'react';
import SubmitButton from '../../buttons/SubmitButton/SubmitButton';
import styles from './EditMode.module.scss';
import AnswerMode from './AnswerMode/AnswerMode';
import QuestionMode from './QuestionMode/QuestionMode';

const EditMode = ({
  mode,
  bodyInput,
  setBodyInput,
  editError,
  removeEditError,
  titleInput,
  handleTitleInput,
  editingRights,
  editErrorMessage,
  requestLoading,
  editMode,
  confirmEdit,
  cancelEdit,
}) => (
  <div className={styles.EditMode}>
    {mode === 'answer' && (
      <AnswerMode
        bodyInput={bodyInput}
        setBodyInput={setBodyInput}
        editError={editError}
        removeEditError={removeEditError}
      />
    )}

    {mode === 'question' && (
      <QuestionMode
        titleInput={titleInput}
        editError={editError}
        removeEditError={removeEditError}
        handleTitleInput={handleTitleInput}
        bodyInput={bodyInput}
        setBodyInput={setBodyInput}
      />
    )}

    {editingRights && editMode && (
      <div className={styles.centered}>
        {editError && <p>{editErrorMessage}</p>}
        <SubmitButton postQuestionLoading={requestLoading} onClick={() => confirmEdit()}>
          Submit
        </SubmitButton>
        <SubmitButton inverted borderless onClick={() => cancelEdit()}>
          Cancel
        </SubmitButton>
      </div>
    )}
  </div>
);

export default EditMode;
