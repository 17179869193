import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaEdit, FaCheck } from 'react-icons/fa';
import styles from './ProfilePage.module.scss';
import { AuthContext, AppContext } from '../../../context/contexts';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
// import ProfileBadges from '../ProfileBadges/ProfileBadges';
// import ProfileBio from '../ProfileBio/ProfileBio';
// import ProfileRecentActivities from '../ProfileRecentActivities/ProfileRecentActivities';

import BackButton from '../../buttons/BackButton/BackButton';

import SubmitButton from '../../buttons/SubmitButton/SubmitButton';
import { extractFromHtml } from '../../../utils/utils';

const ProfilePage = () => {
  const authContext = useContext(AuthContext);
  const appContext = useContext(AppContext);
  const {
    allUsers,
    getAllUsers,
    getUserFromLocalStorage,
    updateProfile,
    updateProfileLocalStorage,
    updateUserInAllUsers,
  } = authContext;
  const { allQuestions } = appContext;
  const { id } = useParams();
  const user = allUsers.find((u) => u.user_id === id);
  const [recentActivity, setRecentActivity] = useState();
  const [editUsername, setEditUsername] = useState(false);
  const [editUserbio, setEditUserbio] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [updateNameValue, setUpdateNameValue] = useState('');
  const [updateBioValue, setUpdateBioValue] = useState('');

  useEffect(() => {
    const myUser = getUserFromLocalStorage();
    if (user && user.user_id && myUser) {
      const parsedMyUser = JSON.parse(myUser);
      setIsCurrentUser(parsedMyUser.user_id === user.user_id);
    }
  }, [user]);

  useEffect(async () => {
    await getAllUsers();
  }, []);

  useEffect(() => {
    // user recentActivity only provides an object with the questionId and answerId and few other information
    // the user questions and user answers need to be extracted from allQuestions separately using the infos
    // from user recentActivity. To filter the right question we need to check if the question's id can be
    // found in the recentActivty array. To get the answers is trickier, each question has an answers array and
    // the user could have answered many different questions, so within each question we need to first iterate
    // and filter the answers and concatenate it in a new array

    if (user) {
      let userQuestions = [];
      if (user && user.recentActivity.length > 0) {
        userQuestions = allQuestions.filter((question) =>
          user.recentActivity.find(
            (activity) =>
              activity.questionId === question._id && question.username === user.username
          )
        );
      }

      // the inner reduce is responsible for filtering the answers like I said above, and add the slug in it
      const innerReduce = (question) =>
        question.answers.reduce((acc, answer) => {
          if (answer.user_id === user.user_id) acc.push({ ...answer, slug: question.slug });
          return acc;
        }, []);

      const userAnswers = allQuestions.reduce(
        (acc, question) => acc.concat(innerReduce(question)),
        []
      );

      const activities = userQuestions
        .concat(userAnswers)
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      activities.forEach(
        (act, i) => act.markedAsAnswer && activities.splice(i, 1) && activities.unshift(act)
      );

      setRecentActivity(activities);
    }
  }, [allQuestions, user]);

  const handleSubmit = () => {
    const myUser = JSON.parse(getUserFromLocalStorage());
    setEditUsername(false);
    setEditUserbio(false);
    updateProfile({
      updatedProfileName: updateNameValue,
      updatedProfileBio: updateBioValue,
      id: myUser._id,
    }).then((res) => {
      if (res.success) {
        updateProfileLocalStorage(res.user);
        updateUserInAllUsers(res.user);
      }
    });
  };

  return (
    <div className={styles.ProfilePage}>
      <div className={styles['ProfilePage-title-container']}>
        <BackButton returnPath="/" className={styles.BackButton} />
        {user && !editUsername && (
          <>
            {!isCurrentUser ? (
              <div className={styles['ProfilePage-username-container']}>
                <h1>{user.username}</h1>
                {user.admin && (
                  <p>
                    {user.first_name} {user.last_name}
                  </p>
                )}
              </div>
            ) : (
              <div className={styles['ProfilePage-userNameEdit']}>
                <h1>{user.username}</h1>
                <FaEdit
                  className={styles['ProfilePage-editButton']}
                  onClick={() => setEditUsername(true)}
                />
              </div>
            )}
          </>
        )}
        {user && editUsername && (
          <div className={styles['ProfilePage-editMode']}>
            <div className={styles['ProfilePage-editModeInput']}>
              <input
                type="text"
                placeholder={user.username}
                value={updateNameValue}
                size="35"
                className={styles['ProfilePage-input']}
                onChange={(e) => setUpdateNameValue(e.target.value)}
              />
            </div>
            <div className={styles['ProfilePage-editModeButtons']}>
              <SubmitButton onClick={handleSubmit} />
              <SubmitButton
                inverted
                borderless
                onClick={() => {
                  setEditUsername(false);
                  setUpdateNameValue('');
                }}
              >
                Cancel
              </SubmitButton>
            </div>
          </div>
        )}
      </div>
      <div className={styles['ProfilePage-content-container']}>
        <div className={styles['ProfilePage-avatar-bio-container']}>
          {user && <ProfileAvatar user={user} />}
          <div className={styles['ProfilePage-bio-container']}>
            {user && !editUserbio && (
              <>
                {!isCurrentUser ? (
                  user.bio
                ) : (
                  <div className={styles['ProfilePage-bio-edit-container']}>
                    {!user.bio ? <i>Write something interesting about yourself</i> : user.bio}
                    <FaEdit
                      className={styles['ProfilePage-editButton']}
                      onClick={() => setEditUserbio(true)}
                    />
                  </div>
                )}
              </>
            )}
            {user && editUserbio && (
              <div className={styles['ProfilePage-editMode']}>
                <textarea
                  type="text"
                  rows="4"
                  cols="35"
                  defaultValue={user.bio}
                  onChange={(e) => setUpdateBioValue(e.target.value)}
                />

                <div className={styles['ProfilePage-editModeButtons']}>
                  <SubmitButton onClick={handleSubmit} />
                  <SubmitButton
                    inverted
                    borderless
                    onClick={() => {
                      setEditUserbio(false);
                      setUpdateBioValue('');
                    }}
                  >
                    Cancel
                  </SubmitButton>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles['badge-activity-container']}>
          <h3>Recent Activity</h3>
          <div>
            {(recentActivity &&
              recentActivity.slice(0, 5).map((ans) => (
                <ul key={ans.createdAt}>
                  {ans.status ? (
                    <li>
                      <span>Asked a question:&nbsp;</span>
                      <Link to={`/questions/${ans._id}/${ans.slug}`}>{ans.title}</Link>
                    </li>
                  ) : (
                    <li>
                      <span>Answer:&nbsp;</span>
                      <Link to={`/questions/${ans.question_id}/${ans.slug}?answerId=${ans._id}`}>
                        {extractFromHtml(ans.body)[0]}
                      </Link>
                      {ans.markedAsAnswer && <FaCheck color="green" />}
                    </li>
                  )}
                </ul>
              ))) || <p>Nothing to show yet</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
