import {
  SET_QUESTIONS,
  QUESTIONS_LOADING,
  QUESTIONS_FETCH_FAILURE,
  SUBMIT_BUTTON_INIT,
  SUBMIT_BUTTON_SUCCESS,
  SUBMIT_BUTTON_FAILURE,
  RESET_BUTTON_FAILURE,
  RESET_POST,
  RECENTLY_ASKED_QUESTION,
  REMOVE_RECENTLY_ASKED_QUESTION,
  ADD_FILTER_TAG,
  REMOVE_FILTER_TAG,
  GET_ALL_TAGS,
  UPDATE_QUESTION,
  FILTER_QUESTIONS_BY_HITS,
  ADD_SEARCH_STRING,
  REMOVE_SEARCH_STRING,
  REMOVE_ALL_FILTER_TAGS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_QUESTIONS:
      return {
        ...state,
        questionsLoading: false,
        timeLastDataUpdate: Date.now(),
        allQuestions: action.payload,
      };
    case QUESTIONS_LOADING:
      return {
        ...state,
        questionsLoading: true,
      };
    case QUESTIONS_FETCH_FAILURE:
      return {
        ...state,
        questionsLoading: false,
        questionsFetchFailure: action.payload,
      };
    case SUBMIT_BUTTON_INIT:
      return {
        ...state,
        submitButtonLoading: true,
      };
    case SUBMIT_BUTTON_SUCCESS:
      return {
        ...state,
        timeLastDataUpdate: Date.now(),
        submitButtonLoading: false,
        submitButtonSuccess: true,
      };
    case SUBMIT_BUTTON_FAILURE:
      return {
        ...state,
        submitButtonLoading: false,
        submitButtonError: true,
      };
    case RESET_BUTTON_FAILURE:
      return {
        ...state,
        submitButtonError: false,
      };
    case RESET_POST:
      return {
        ...state,
        submitButtonLoading: false,
        submitButtonError: false,
        submitButtonSuccess: false,
      };
    case RECENTLY_ASKED_QUESTION:
      return {
        ...state,
        recentlyAskedQuestion: action.payload,
      };
    case REMOVE_RECENTLY_ASKED_QUESTION:
      return {
        ...state,
        recentlyAskedQuestion: {},
      };
    case GET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
      };
    case ADD_FILTER_TAG:
      return {
        ...state,
        filteredTags: [...state.filteredTags, action.payload],
      };

    case ADD_SEARCH_STRING:
      return {
        ...state,
        searchString: action.payload,
      };
    case REMOVE_FILTER_TAG:
      return {
        ...state,
        filteredTags: state.filteredTags.filter((tag) => tag !== action.payload),
      };
    case REMOVE_ALL_FILTER_TAGS:
      return {
        ...state,
        filteredTags: '',
      };
    case REMOVE_SEARCH_STRING:
      return {
        ...state,
        searchString: '',
      };
    case FILTER_QUESTIONS_BY_HITS:
      return {
        ...state,
        filteredQuestions: action.payload.reduce((acc, hit) => {
          const filteredQuestion = state.allQuestions.find(
            (question) => question._id === hit.objectID
          );
          if (filteredQuestion) acc.push(filteredQuestion);
          return acc;
        }, []),
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        allQuestions: state.allQuestions.map((question) =>
          question._id === action.payload._id ? action.payload : question
        ),
        filteredQuestions: state.filteredQuestions.map((question) =>
          question._id === action.payload._id ? action.payload : question
        ),
      };
    default:
      return state;
  }
};
