import React from 'react';
import { Configure } from 'react-instantsearch-dom';
import AlgoliaTagRefinement from './AlgoliaTagRefinement';
import AlgoliaConnectHits from './AlgoliaConnectHits';

const AlgoliaSetup = () => (
  <>
    <Configure hitsPerPage={10} />
    <AlgoliaConnectHits />
    <AlgoliaTagRefinement
      attribute="_tags"
      limit={10}
      operator="and"
      defaultRefinement={['newtag']}
    />
  </>
);

export default AlgoliaSetup;
