/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { AppContext } from '../../../context/contexts';
import { EditMode, NormalMode, DeleteMode } from '../../EditDelete';
import UserInfo from '../../UserInfo/UserInfo';
import styles from './Answer.module.scss';

const Answer = ({ answer, question, approveAnswer, editingRights }) => {
  const { updateAnswer, deleteAnswer } = useContext(AppContext);
  const { _id: answerId } = answer;
  const [bodyInput, setBodyInput] = useState('');
  const [editError, setEditError] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => setBodyInput(answer.body), []);

  const cancelEdit = () => {
    setEditMode(false);
    setDeleteMode(false);
    setBodyInput(answer.body);
  };

  const confirmEdit = () => {
    // Check if updated values are different from original.
    const bodyDiff = answer.body.localeCompare(bodyInput);
    if (bodyDiff !== 0) {
      setRequestLoading(true);
      updateAnswer({ answerId, bodyInput, questionId: question._id })
        .then(() => {
          setRequestLoading(false);
          setEditMode(false);
        })
        .catch((err) => {
          console.warn(err);
          setRequestLoading(false);
          setEditErrorMessage(
            'There appears to be issue with your edit. Check there is enough characters in the Body.'
          );
          setEditError(true);
        });
    } else {
      setEditErrorMessage('No difference!');
      setEditError(true);
    }
  };

  const confirmDelete = () => {
    setRequestLoading(true);
    deleteAnswer({ questionId: question._id, answerId }).then(() => {
      setRequestLoading(false);
    });
  };

  const removeEditError = () => {
    setEditError(false);
    setEditErrorMessage('');
  };

  return (
    <div className={styles['Answer-container']}>
      <div className={styles['Answer-sidebox']}>
        {answer.markedAsAnswer && <FaCheck className={styles.check} />}
      </div>
      <div className={styles.Answer}>
        <div dangerouslySetInnerHTML={{ __html: answer.body }} className={styles['answer-text']} />
        {editingRights && !answer.markedAsAnswer && (
          <div>
            <button
              className="basic-button edit-button"
              type="button"
              onClick={() => {
                approveAnswer(answer, question);
              }}
            >
              Mark as answer
            </button>
          </div>
        )}
        {editMode && (
          <EditMode
            mode="answer"
            bodyInput={bodyInput}
            setBodyInput={setBodyInput}
            editError={editError}
            removeEditError={removeEditError}
            editingRights={editingRights}
            editErrorMessage={editErrorMessage}
            requestLoading={requestLoading}
            editMode={editMode}
            confirmEdit={confirmEdit}
            cancelEdit={cancelEdit}
          />
        )}
        <div className={styles['Answer-info-container']}>
          {editingRights && !editMode && !deleteMode && (
            <NormalMode setEditMode={setEditMode} setDeleteMode={setDeleteMode} />
          )}
          {editingRights && deleteMode && (
            <DeleteMode
              requestLoading={requestLoading}
              confirmDelete={confirmDelete}
              cancelEdit={cancelEdit}
              className={styles['Answer-delete-mode']}
            />
          )}
          <UserInfo
            timestamp={answer.updatedAt ? answer.updatedAt : question.createdAt}
            className="QuestionPage-ask-user"
            insideAnswer
            transparentBackground
            userId={answer.user_id}
          />
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Answer;
