import React, { useState, useRef, useEffect } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import { IoFunnel } from 'react-icons/io5';
import cx from 'classnames';

import './SortBy.scss';

const SortBy = ({ items, currentRefinement, refine }) => {
  const [visible, setVisible] = useState(false);
  const [label, setLabel] = useState('Filter');

  const filterDropdown = useRef();

  const handleMenu = () => {
    setVisible(!visible);
  };

  const handleRefinement = (item) => (e) => {
    refine(e.currentTarget.getAttribute('value'));
    setLabel(item.label);
  };

  const handleOutsideClick = (e) => {
    if (!filterDropdown.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={cx('Test-menu', {
        dropdown: 'dropdown',
        selection: 'selection',
        active: visible ? 'active' : '',
        visible: visible ? 'visible' : '',
      })}
      onClick={handleMenu}
      onKeyPress={handleMenu}
      role="button"
      tabIndex="-1"
      ref={filterDropdown}
    >
      <IoFunnel
        className={cx('Test-menu', {
          funnel: 'funnel',
        })}
      />
      <div
        className={cx('Test-menu', {
          default: 'default',
          text: label === 'Filter' ? 'text' : '',
        })}
      >
        {label}
      </div>
      <div
        className={cx('Test-menu', {
          transition: 'transition',
          visible: visible ? 'visible' : '',
          hidden: !visible ? 'hidden' : '',
        })}
        tabIndex="-1"
      >
        {items.map((item) => (
          <div
            className={cx('Test-menu', {
              item: visible ? 'item' : '',
            })}
            key={item.label}
            value={item.isRefined ? currentRefinement : item.value}
            onClick={handleRefinement(item)}
            onKeyPress={handleRefinement(item)}
            role="button"
            tabIndex="-1"
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};
export default connectSortBy(SortBy);
