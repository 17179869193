import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import styles from './BugReportForm.module.scss';

import BackButton from '../buttons/BackButton/BackButton';
import SubmitButton from '../buttons/SubmitButton/SubmitButton';
import TextEditor from '../TextEditor/TextEditor';
import { AppContext, AuthContext } from '../../context/contexts';
import { validateTextEditorBody, extractFromHtml } from '../../utils/utils';

const BugReportForm = () => {
  const [bodyValue, setBodyValue] = useState('');
  const [bodyError, setBodyError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const {
    reportBug,
    resetButtonFailure,
    submitButtonLoading,
    submitButtonError,
    submitButtonSuccess,
  } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const submitQuestion = () => {
    const isBody = validateTextEditorBody(bodyValue);
    if (!isBody) {
      setBodyError(true);
      return;
    }

    if (!bodyError) {
      const bodyRaw = extractFromHtml(bodyValue).reduce((acc, v) => `${acc}${v}\n`, '');

      const bugData = {
        username: user.username,
        user_id: user.user_id,
        body: bodyRaw,
      };

      if (canSubmit) {
        setCanSubmit(false);
        reportBug(bugData);
      }
    }
  };

  useEffect(() => {
    if (submitButtonSuccess) {
      setTimeout(() => setRedirect(true), 1000);
    }
  }, [submitButtonSuccess]);

  if (redirect === true) {
    return <Redirect to="/" />;
  }

  const handleSetValue = (e) => {
    setBodyValue(e);
    if (bodyError) setBodyError(false);
    if (submitButtonError) resetButtonFailure();
    if (!canSubmit) setCanSubmit(true);
  };

  return (
    <div className={styles.BugReportForm}>
      <div className={styles['BugReportForm-title-container']}>
        <BackButton returnPath="/" className={styles.BackButton} />
        <h1>Report a Bug</h1>
      </div>
      <div className={styles['BugReportForm-subtitle-container']}>
        <h3>Description</h3>
        <p>Describe the location, behavior and the steps you took to reproduce the bug.</p>
      </div>

      <div className={styles['input-container']}>
        <TextEditor value={bodyValue} setValue={handleSetValue} className={styles.TextEditor} />
        {bodyError && (
          <span className={styles['error-message']}>Please describe your question..</span>
        )}
      </div>
      <div className={styles['submit-container']}>
        <SubmitButton
          submitButtonLoading={submitButtonLoading}
          successfulResponseFromServer={submitButtonSuccess}
          onClick={submitQuestion}
          className={styles.SubmitButton}
          error={bodyError || submitButtonError}
        />

        {submitButtonSuccess && <div>Submitted!</div>}
      </div>
    </div>
  );
};

export default BugReportForm;
