import React, { useContext } from 'react';
import styles from './Tag.module.scss';
import { AppContext } from '../../context/contexts';

const Tag = ({ currentlyFilteringByTag, children }) => {
  const appContext = useContext(AppContext);
  const { addFilterTag, removeFilterTag } = appContext;

  if (currentlyFilteringByTag) {
    return (
      <button type="button" className={styles.Tag} onClick={() => removeFilterTag(children)}>
        {children.toString().toLowerCase()} <span>×</span>
      </button>
    );
  }
  return (
    <button type="button" className={styles.Tag} onClick={() => addFilterTag(children)}>
      {children.toString().toLowerCase()}
    </button>
  );
};

export default Tag;
