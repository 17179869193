import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Tag from '../../Tag/Tag';
import UserInfo from '../../UserInfo/UserInfo';
import styles from './QuestionListItem.module.scss';

const cx = classNames.bind(styles);

const QuestionListItem = ({
  questionDetails,
  recentlyAskedQuestion,
  removeRecentlyAskedQuestion,
}) => {
  useEffect(() => {
    // Used for the animation.
    if (recentlyAskedQuestion._id && questionDetails._id === recentlyAskedQuestion._id) {
      setTimeout(() => {
        removeRecentlyAskedQuestion();
      }, []);
    }
  }, [recentlyAskedQuestion, removeRecentlyAskedQuestion, questionDetails]);

  const truncate = (str, n) => (str.length > n ? `${str.substr(0, n - 1)}...` : str);

  const replaceHtml = (string) =>
    string.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;|&gt;/g, ' ');
  const strippedString = truncate(replaceHtml(questionDetails.body.split('<pre')[0]), 500);
  const numberOfAnswers = questionDetails.answers.length;

  return (
    <div
      className={cx(styles.QuestionListItem, {
        recentQuestion:
          recentlyAskedQuestion._id && questionDetails._id === recentlyAskedQuestion._id,
      })}
    >
      <div className={styles['answers-votes-container']}>
        {/* 
        Commented out until we implement votes
        <div className={styles['votes-container']}>
          <span>{Math.floor(Math.random() * (100 - 0) + 0)}</span> votes
        </div>
        */}
        <div
          className={cx(styles['answers-container'], {
            answered: questionDetails.answered,
          })}
        >
          <>
            <span>{numberOfAnswers}</span> <br />
            {numberOfAnswers === 1 ? 'answer' : 'answers'}
          </>
        </div>
      </div>
      <div className={styles['question-details-container']}>
        <h3>
          <Link
            to={`/questions/${questionDetails._id}/${questionDetails.slug}`}
            className={styles['question-link']}
          >
            {questionDetails.title}
          </Link>
        </h3>
        <p className={styles['question-body']}>{strippedString}</p>
        <div className={styles['tags-container']}>
          {questionDetails._tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      </div>
      <UserInfo
        timestamp={questionDetails.createdAt}
        className={styles['QuestionListItem-UserInfo']}
        userId={questionDetails.user_id}
      />
    </div>
  );
};

export default QuestionListItem;
