import React from 'react';
import classNames from 'classnames';
import styles from './ContentBox.module.scss';

const ContentBox = ({ children, width, height, className, centered, fillWidth }) => (
  <div
    style={{ width, height, margin: centered ? '1rem auto' : '', flex: fillWidth ? '1' : '' }}
    className={classNames(styles.container, className)}
  >
    {children}
  </div>
);

export default ContentBox;
