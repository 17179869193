import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import './AskAQuestionButton.scss';

const AskAQuestionButton = () => {
  const [party, setParty] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [mouseClick, setMouseClick] = useState(false);
  const time = 5000;

  useEffect(() => {
    if (hovering) {
      const timeout = setTimeout(() => {
        setParty(hovering);
      }, time);
      return () => {
        clearTimeout(timeout);
      };
    }
    return null;
  }, [hovering, time]);

  const detectHover = () => {
    setHovering(true);
  };

  const removeHover = () => {
    setHovering(false);
    setParty(false);
  };

  const getAudioContext = () => {
    // eslint-disable-next-line no-global-assign
    AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContent = new AudioContext();
    return audioContent;
  };

  const getFunky = async () => {
    const response = await axios.get(
      'https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/gonna-make-you-sweat.mp3',
      {
        responseType: 'arraybuffer',
      }
    );
    // create audio context
    const audioContext = getAudioContext();
    // create audioBuffer (decode audio file)
    const audioBuffer = await audioContext.decodeAudioData(response.data);

    // create audio source
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);

    // play audio
    source.start();
  };

  return (
    <div className="AskAQuestionButton">
      {party ? (
        <button
          onClick={getFunky}
          onMouseOut={removeHover}
          onBlur={removeHover}
          onMouseDown={() => setMouseClick(true)}
          onMouseUp={() =>
            setTimeout(() => {
              setMouseClick(false);
            }, 500)
          }
          type="button"
          className={classNames('push--skeuo', 'funky', {
            pressed: mouseClick,
          })}
        >
          PARTY!
        </button>
      ) : (
        <Link to="askaquestion">
          <button
            onMouseOut={removeHover}
            onBlur={removeHover}
            onMouseOver={detectHover}
            onFocus={detectHover}
            onMouseDown={() => {
              setMouseClick(true);
              setTimeout(() => {
                setMouseClick(false);
              }, 500);
            }}
            onMouseUp={() =>
              setTimeout(() => {
                <Redirect to="/askaquestion" push />;

                setMouseClick(false);
              }, 500)
            }
            type="button"
            className={classNames('push--skeuo', {
              pressed: mouseClick,
            })}
          >
            Ask Question
          </button>
        </Link>
      )}
    </div>
  );
};

export default AskAQuestionButton;
