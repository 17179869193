import React, { useReducer } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from '../contexts';
import AuthReducer from './authReducer';
import {
  SET_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  ALL_USERS,
  LOGOUT,
  UPDATE_USER_IN_ALL_USERS,
  REGISTER_USER_FROM_COOKIE,
} from '../types';

const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKEND : '';

const AuthState = (props) => {
  const { children } = props;
  const initialState = {
    token: null,
    // isAuthenticated: !!Cookies.get('secondaryAuthToken'),
    isAuthenticated: !!localStorage.getItem('token'),
    loading: true,
    user: null,
    error: null,
    jwt: null,
    allUsers: [],
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };

  const updateProfile = (updatedProfile) => {
    const token = localStorage.getItem('token');
    return axios
      .post(`${api}/users/update-profile`, updatedProfile, {
        headers: { Authorization: token },
      })
      .then((res) => res.data);
  };

  const updateProfileLocalStorage = (profile) => {
    localStorage.setItem('profile', JSON.stringify(profile));
  };

  const getAllUsers = () => {
    const token = localStorage.getItem('token');

    return axios
      .get(`${api}/users`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        dispatch({ type: ALL_USERS, payload: res.data.users });
        return res.data.users;
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  const updateUserInAllUsers = (user) => {
    const newUsersArray = [...state.allUsers];
    const foundIndex = newUsersArray.findIndex((u) => u.user_id === user.user_id);
    newUsersArray[foundIndex] = user;
    dispatch({ type: UPDATE_USER_IN_ALL_USERS, payload: newUsersArray });
  };

  const checkIfUserExists = (profile, userList) => {
    const token = localStorage.getItem('token');
    const existingProfile = userList.filter((user) => user.user_id === profile.user.id);

    if (existingProfile.length > 0) {
      return existingProfile[0];
    }

    return axios
      .post(`${api}/users`, profile, {
        headers: { Authorization: token },
      })
      .then((res) => res.data.user)
      .catch((err) => {
        console.log(err);
      });
  };

  const registerUser = async (code) => {
    setLoading();
    try {
      const res = await axios.get(`${api}/auth/discord/callback/${code}`);
      const { token } = res.data;
      await localStorage.setItem('token', token);
      const userList = await getAllUsers();
      const profile = await checkIfUserExists(res.data.profile, userList);
      localStorage.setItem('profile', JSON.stringify(profile));

      dispatch({ type: REGISTER_SUCCESS, payload: profile });
    } catch (err) {
      dispatch({ type: REGISTER_FAIL, payload: 'no-role' });
    }
  };

  const getUserFromLocalStorage = () => {
    const profile = localStorage.getItem('profile');
    if (profile !== undefined) {
      const profileObject = JSON.parse(profile);
      dispatch({ type: REGISTER_USER_FROM_COOKIE, payload: profileObject });
    } else {
      Cookies.remove('secondaryAuthToken');
    }
    return profile;
  };

  const logout = () => {
    Cookies.remove('secondaryAuthToken');
    Cookies.remove('profile');
    localStorage.removeItem('token');
    dispatch({ type: LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        allUsers: state.allUsers,
        registerUser,
        logout,
        getUserFromLocalStorage,
        getAllUsers,
        updateProfile,
        updateProfileLocalStorage,
        updateUserInAllUsers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
