import React from 'react';
import { FaBug } from 'react-icons/fa';

import styles from './Bug.module.scss';

const Bug = () => (
  <div className={styles.Bug}>
    <FaBug title="Report a Bug" size="30px" />
    {/* <span>Report a Bug</span> */}
  </div>
);

export default Bug;
