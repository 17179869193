import React from 'react';

import styles from './NormalMode.module.scss';

const NormalMode = ({ setEditMode, setDeleteMode }) => (
  <div className={styles.EditContainer}>
    <button
      className={`${styles['basic-button']} ${styles['edit-button']}`}
      type="button"
      onClick={() => setEditMode(true)}
    >
      Edit
    </button>
    /
    <button
      className={`${styles['basic-button']} ${styles['delete-button']}`}
      type="button"
      onClick={() => setDeleteMode(true)}
    >
      Delete
    </button>
  </div>
);

export default NormalMode;
