import React, { useState, useContext, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import ReactTags from 'react-tag-autocomplete';

import styles from './AskQuestion.module.scss';

import BackButton from '../buttons/BackButton/BackButton';
import SubmitButton from '../buttons/SubmitButton/SubmitButton';
import TextEditor from '../TextEditor/TextEditor';
import { AppContext, AuthContext } from '../../context/contexts';
import { validateTextEditorBody } from '../../utils/utils';

const AskQuestion = () => {
  const [titleValue, setTitleValue] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [bodyValue, setBodyValue] = useState('');
  const [bodyError, setBodyError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const [canSubmit, setCanSubmit] = useState(true);

  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const reactTags = useRef();

  const {
    postQuestion,
    submitButtonError,
    submitButtonLoading,
    submitButtonSuccess,
    resetButtonFailure,
    fetchTags,
    allTags,
  } = appContext;

  const { user } = authContext;
  const formattedTags = allTags.map((tag) => tag.tag_title);

  useEffect(() => {
    fetchTags();
  }, []);

  const submitQuestion = () => {
    const isBody = validateTextEditorBody(bodyValue);
    if (!titleValue || !isBody) {
      if (!titleValue) {
        setTitleError(true);
      }
      if (!isBody) {
        setBodyError(true);
      }
      return;
    }

    if (!bodyError && !titleError) {
      const tags = currentTags.map((tag) => tag.name);

      const questionData = {
        title: titleValue,
        username: user.username,
        user_id: user.user_id,
        body: bodyValue,
        status: 'open',
        _tags: tags,
        uniqueTags: currentTags.filter(
          (curTag) => !formattedTags.some((allTag) => curTag.name === allTag.name)
        ),
      };

      if (canSubmit) {
        setCanSubmit(false);
        postQuestion(questionData);
      }
    }
  };

  useEffect(() => {
    if (submitButtonSuccess) {
      setTimeout(() => setRedirect(true), 1000);
    }
  }, [submitButtonSuccess]);

  if (redirect === true) {
    return <Redirect to="/" />;
  }

  const addTag = (tag) => {
    // eslint-disable-next-line no-param-reassign
    tag.name = tag.name.toLowerCase();
    setCurrentTags([...currentTags, tag]);
  };

  const removeTag = (tag) => {
    const tags = currentTags.slice(0);
    tags.splice(tag, 1);
    setCurrentTags(tags);
  };

  const handleSetValue = (e) => {
    setBodyValue(e);
    if (bodyError) setBodyError(false);
    if (submitButtonError) resetButtonFailure();
    if (!canSubmit) setCanSubmit(true);
  };

  return (
    <div className={styles.AskQuestion}>
      <div className={styles['AskQuestion-title-container']}>
        <BackButton returnPath="/" className={styles.BackButton} />
        <h1>Ask A Question</h1>
      </div>
      <div className={styles['AskQuestion-subtitle-container']}>
        <h3>Title</h3>
        <p>Be specific and imagine you’re asking a question to another person</p>
      </div>
      <div className={styles['input-container']}>
        <input
          value={titleValue}
          onFocus={() => titleError && setTitleError(false)}
          onChange={(e) => setTitleValue(e.target.value)}
        />
        {titleError && <span className={styles['error-message']}>Please enter a Title</span>}
      </div>
      <div className={styles['AskQuestion-subtitle-container']}>
        <h3>Body</h3>
        <p>Include all the information someone would need to answer your question</p>
      </div>

      <div className={styles['input-container']}>
        <TextEditor value={bodyValue} setValue={handleSetValue} className={styles.TextEditor} />
        {bodyError && (
          <span className={styles['error-message']}>Please describe your question..</span>
        )}
      </div>
      <div className={styles['AskQuestion-subtitle-container']}>
        <h3>Tags</h3>
        <p>Add up to 5 tags to describe what your question is about</p>
      </div>
      <ReactTags
        ref={reactTags}
        tags={currentTags}
        suggestions={formattedTags}
        onDelete={(e) => removeTag(e)}
        onAddition={(e) => addTag(e)}
        placeholderText="Add new tag"
        noSuggestionsText="No result found, press 'Enter' to create tag."
        allowNew={currentTags.length < 5}
      />

      <div className={styles['submit-container']}>
        <SubmitButton
          onClick={submitQuestion}
          submitButtonLoading={submitButtonLoading}
          successfulResponseFromServer={submitButtonSuccess}
          className={styles.SubmitButton}
          error={titleError || bodyError || submitButtonError}
        />

        {submitButtonSuccess && <div>Submitted!</div>}
      </div>
    </div>
  );
};

export default AskQuestion;
