import React from 'react';
import classNames from 'classnames';

import SubmitButton from '../../buttons/SubmitButton/SubmitButton';

const DeleteMode = ({
  mode = 'question',
  requestLoading,
  confirmDelete,
  cancelEdit,
  className,
}) => (
  <div className={classNames('edit-container centered delete-container', className)}>
    <p className="delete-confirmation">
      Are you sure? This action cannot be undone and your {mode} may still be relevant for other
      students.
    </p>

    <div className="delete-buttons">
      <SubmitButton
        postQuestionLoading={requestLoading}
        color="#fd495c"
        onClick={() => confirmDelete()}
      >
        Confirm
      </SubmitButton>
      <SubmitButton inverted borderless color="#fd495c" onClick={() => cancelEdit()}>
        Cancel
      </SubmitButton>
    </div>
  </div>
);
export default DeleteMode;
