import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-dom';

import { algoliaClient, indexName } from '../../../algolia/algoliaClient';
import AlgoliaSetup from '../../../algolia/AlgoliaSetup';
import { AuthContext, AppContext } from '../../../context/contexts';
import styles from './Main.module.scss';
import ContentBox from '../ContentBox/ContentBox';
import AskQuestion from '../../AskQuestion/AskQuestion';
import ProfilePage from '../../Profile/ProfilePage/ProfilePage';
import Header from '../../Header/Header';
import ProfileAvatar from '../../Profile/ProfileAvatar/ProfileAvatar';
// import ProfileBadges from '../../Profile/ProfileBadges/ProfileBadges';
import AskAQuestionButton from '../../AskQuestion/AskAQuestionButton';
import QuestionList from '../../Questions/QuestionList/QuestionList';
import QuestionPage from '../../Questions/QuestionPage/QuestionPage';
import Bug from '../../Bug/Bug';
import BugReportForm from '../../Bug/BugReportForm';

const Main = () => {
  const { path } = useRouteMatch();
  const [refreshAlgolia, setRefreshAlgolia] = useState(false);
  const [userPresent, setUserPresent] = useState(false);

  const authContext = useContext(AuthContext);
  const appContext = useContext(AppContext);

  const { logout, user, getUserFromLocalStorage, allUsers, getAllUsers } = authContext;
  const {
    fetchQuestions,
    questionsFetchFailure,
    filteredQuestions,
    recentlyAskedQuestion,
    checkIfObjectIsEmpty,
    allQuestions,
  } = appContext;

  useEffect(() => {
    if (user && user.user_id && allUsers.length > 0) {
      const checkUser = allUsers.some((singleUser) => singleUser.user_id === user.user_id);
      if (checkUser) {
        setUserPresent(true);
      } else {
        getAllUsers();
      }
    }
  }, [user, allUsers]);

  useEffect(() => {
    if (allUsers.length === 0) {
      getAllUsers();
    }
  }, [allUsers]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (!user) {
      getUserFromLocalStorage();
    }
  }, [user]);

  const refeshAlgolia = () => {
    // Refresh Algolia if we delete an item, aka: the length of allQuestions changes.
    setRefreshAlgolia(true);
    setTimeout(() => {
      setRefreshAlgolia(false);
    }, 1000);
  };

  useEffect(() => {
    refeshAlgolia();
  }, [allQuestions]);

  useEffect(() => {
    if (!checkIfObjectIsEmpty(recentlyAskedQuestion)) {
      setRefreshAlgolia(true);
      filteredQuestions.forEach((question) => {
        if (question._id === recentlyAskedQuestion._id) {
          setRefreshAlgolia(false);
        }
      });
    }
  }, [recentlyAskedQuestion, filteredQuestions, refreshAlgolia]);

  if (!user) return <div>...</div>;

  return (
    <InstantSearch indexName={indexName} searchClient={algoliaClient} refresh={refreshAlgolia}>
      <AlgoliaSetup />
      <main className={styles.Main}>
        <Header logout={logout} />
        <div className={styles.content}>
          <Switch>
            <Route exact path={path}>
              <>
                <ContentBox className={styles['ContentBox-questions']}>
                  <QuestionList filteredQuestions={filteredQuestions} userPresent={userPresent} />
                  {questionsFetchFailure && (
                    <div>
                      <div>Failed to fetch questions... try again?</div>
                      <button type="button">Click</button>
                    </div>
                  )}
                </ContentBox>
                <ContentBox className={styles['ContentBox-profile']}>
                  <Link to={`/profile/${(user && user.user_id) || ''}`}>
                    <ProfileAvatar hoverEffect />
                    {authContext.user && <h2>{authContext.user.username}</h2>}
                  </Link>
                  <AskAQuestionButton />
                  {/* <ProfileBadges /> */}
                </ContentBox>
                <Link to="/reportabug">
                  <Bug />
                </Link>
              </>
            </Route>

            <Route exact path="/askaquestion">
              <ContentBox width="70vw" centered>
                <AskQuestion />
              </ContentBox>
            </Route>
            <Route exact path="/reportabug">
              <ContentBox width="70vw" centered>
                <BugReportForm />
              </ContentBox>
            </Route>
            <Route exact path="/profile/:id">
              <ContentBox width="70vw" centered>
                <ProfilePage />
              </ContentBox>
            </Route>
            <Route path="/questions/:id/:slug">
              <ContentBox width="70vw" centered className="">
                {allQuestions.length > 0 && <QuestionPage questions={allQuestions} user={user} />}
              </ContentBox>
            </Route>
          </Switch>
        </div>
      </main>
    </InstantSearch>
  );
};

export default Main;
