import React from 'react';
import TextEditor from '../../../TextEditor/TextEditor';

import styles from './QuestionMode.module.scss';

const QuestionMode = ({
  titleInput,
  editError,
  removeEditError,
  handleTitleInput,
  bodyInput,
  setBodyInput,
}) => (
  <div className={styles.QuestionMode}>
    <div className={styles['title-edit-container']}>
      <h3>Title</h3>

      <input
        className={styles['title-input']}
        value={titleInput}
        onFocus={() => {
          if (editError) removeEditError();
        }}
        onChange={(e) => handleTitleInput(e)}
      />
      <div>
        <h3>Body</h3>
        <TextEditor
          value={bodyInput}
          setValue={setBodyInput}
          onFocus={() => {
            if (editError) removeEditError();
          }}
        />
      </div>
    </div>
  </div>
);

export default QuestionMode;
