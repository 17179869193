import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import TextEditor from '../../TextEditor/TextEditor';
import SubmitButton from '../../buttons/SubmitButton/SubmitButton';
import { AppContext, AuthContext } from '../../../context/contexts';
import { validateTextEditorBody } from '../../../utils/utils';
import styles from './AnswerBox.module.scss';

const cx = classNames.bind(styles);

const AnswerBox = ({ questionId }) => {
  const { addNewAnswer } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const [value, setValue] = useState('');
  const [openTextEditor, setOpenTextEditor] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [currentRef, setCurrentRef] = useState();

  const handleOpenTextEditor = () => setOpenTextEditor(true);
  const handleRef = (ref) => setCurrentRef(ref);
  const handleSetValue = (e) => {
    setValue(e);
    if (bodyError) setBodyError(false);
  };

  const submitAnswer = () => {
    if (!validateTextEditorBody(value)) {
      setBodyError(true);
    } else {
      const answerData = {
        body: value,
        questionId,
        userId: user.user_id,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };
      addNewAnswer(answerData);
      setValue('');
      setOpenTextEditor(false);
    }
  };

  useEffect(() => {
    if (currentRef) currentRef.current.focus();
  }, [currentRef]);

  return (
    <>
      <div className={styles.AnswerBox}>
        <img
          src={`https://cdn.discordapp.com/avatars/${user.user_id}/${user.avatar}.png`}
          alt="profile"
        />
        <div
          className={cx({
            'input-container': openTextEditor,
            'input-answer': !openTextEditor,
          })}
          onClick={handleOpenTextEditor}
          onKeyPress={handleOpenTextEditor}
          role="button"
          tabIndex="0"
        >
          {(openTextEditor && (
            <TextEditor
              value={value}
              onFocus={() => bodyError && setBodyError(false)}
              setValue={handleSetValue}
              className={styles['text-editor']}
              handleRef={handleRef}
            />
          )) || <span>Your answer here...</span>}
        </div>
      </div>
      {openTextEditor && (
        <div className={styles['button-container']}>
          <SubmitButton onClick={submitAnswer} error={bodyError} />
          <SubmitButton inverted borderless onClick={() => setOpenTextEditor(false)}>
            Cancel
          </SubmitButton>
        </div>
      )}
    </>
  );
};

export default AnswerBox;
