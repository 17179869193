import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SearchBox from '../SearchBox/SearchBox';

import logo from '../../assets/img/logo.svg';
import styles from './Header.module.scss';
import './ribbon.scss';
import 'instantsearch.css/themes/reset.css';
import { AppContext } from '../../context/contexts';

const Header = ({ logout, authenticated = true }) => {
  const appContext = useContext(AppContext);

  const handleLogoClick = () => {
    if (authenticated && appContext) {
      appContext.removeSearchString();
      appContext.removeAllFilterTags();
    }
  };

  return (
    <header className={styles.Header}>
      <Link onClick={handleLogoClick} to="/">
        <img src={logo} className={styles.logo} alt="WBS CODING SCHOOL logo" />
      </Link>
      <div className="box">
        <div className="ribbon ribbon-top-left">
          <span>beta</span>
        </div>
      </div>
      {authenticated && (
        <div className={styles['searchbar-container']}>
          <button className={styles.logout} type="button" onClick={logout}>
            Sign Out
          </button>
          <SearchBox className={styles.SearchBox} />
        </div>
      )}
    </header>
  );
};

export default Header;
