import React from 'react';
import styles from './Ellipsis.module.scss';

const Ellipsis = () => (
  <div className={styles['animated-ellipsis']}>
    <span className={styles.one}>.</span>
    <span className={styles.two}>.</span>
    <span className={styles.three}>.</span>
  </div>
);

export default Ellipsis;
