import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import classNames from 'classnames/bind';
import styles from './SubmitButton.module.scss';

const cx = classNames.bind(styles);

const SubmitButton = ({
  onClick,
  submitButtonLoading,
  successfulResponseFromServer,
  error,
  className,
  children,
  inverted,
  color,
  borderless,
}) => {
  const [dynamicClassName, setDynamicClassName] = useState('');
  const [buttonContent, setButtonContent] = useState(children || 'Submit');
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (submitButtonLoading) {
      setDynamicClassName(styles.submitting);
    } else {
      setDynamicClassName('');
    }
  }, [submitButtonLoading]);

  useEffect(() => {
    if (successfulResponseFromServer) {
      setButtonContent(<FaCheck />);
      setDynamicClassName(styles.validated);
    }
  }, [successfulResponseFromServer]);

  let customStyling = {};
  if (color) {
    customStyling = {
      backgroundColor: color,
      border: `2px solid ${color}`,
    };
  }
  if ((color && hover) || (color && inverted)) {
    customStyling = {
      backgroundColor: 'white',
      color,
      border: `2px solid ${color}`,
    };
  }
  if (color && borderless) {
    customStyling.border = '';
  }
  if (color && submitButtonLoading) {
    customStyling.border = '3px grey solid';
    customStyling.backgroundColor = 'white';
    customStyling.borderLeftColor = color;
  }
  return (
    <div
      className={cx(styles.SubmitButton, className, {
        inverted,
        borderless,
      })}
    >
      <button
        style={customStyling}
        type="submit"
        className={error ? styles.error : dynamicClassName}
        onClick={onClick}
        onMouseEnter={() => color && setHover(true)}
        onMouseLeave={() => color && setHover(false)}
      >
        {submitButtonLoading ? '' : buttonContent}
      </button>
    </div>
  );
};

export default SubmitButton;
