import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['code-block'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

const TextEditor = ({ value, setValue, onFocus, className, placeholder, onBlur, handleRef }) => {
  const currentRef = useRef();

  useEffect(() => {
    if (handleRef) handleRef(currentRef);
  }, [currentRef]);

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      modules={modules}
      onChange={setValue}
      className={className}
      placeholder={placeholder}
      ref={currentRef}
    />
  );
};

export default TextEditor;
